import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Helmet } from "react-helmet"
import * as style from "styles/insights.module.scss"

class Insights extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Insights" />
        <Helmet>
          <body className={style.insightsBody} />
        </Helmet>

        <div className={`${style.insightsHeader}`}>
          <h1 className="display-1 w-100 align-self-center text-center">
            Insights
          </h1>
        </div>
        <div className="main">
          <h3>Articles</h3>
          <h3>Case Studies</h3>
          <h3>Tech-Notes</h3>
        </div>
      </Layout>
    )
  }
}

export default Insights
